import { useId } from 'react'
import clsx from 'clsx'

export const formClasses =
  'block w-full appearance-none rounded-lg border shadow-sm border-gray-300 p-2 focus:border-blueberry-500 focus:outline-none focus:ring-blueberry-500 sm:text-sm'

export function Label({
  id,
  children,
  required = false,
}: {
  id: string
  children: React.ReactNode
  required?: boolean
}) {
  return (
    <label htmlFor={id} className="mb-2 block text-sm font-semibold">
      {children}
      {required && '*'}
    </label>
  )
}

export function TextField({
  label,
  type = 'text',
  className,
  required = false,
  error = false,
  helperText,
  ...props
}: Omit<React.ComponentPropsWithoutRef<'input'>, 'id'> & {
  label?: string
  error?: boolean
  helperText?: string
}) {
  const id = useId()

  return (
    <div className={className}>
      {label && (
        <Label id={id} required={required}>
          {label}
        </Label>
      )}
      <input
        id={id}
        type={type}
        {...props}
        className={clsx(formClasses, error && 'border-red-500')}
        required={required}
      />
      {helperText && (
        <p
          className={clsx(
            'mt-1 text-sm',
            error ? 'text-red-500' : 'text-gray-500',
          )}
        >
          {helperText}
        </p>
      )}
    </div>
  )
}

export function SelectField({
  label,
  className,
  required = false,
  ...props
}: Omit<React.ComponentPropsWithoutRef<'select'>, 'id'> & { label?: string }) {
  const id = useId()

  return (
    <div className={className}>
      {label && (
        <Label id={id} required={required}>
          {label}
        </Label>
      )}
      <select
        id={id}
        {...props}
        className={clsx(formClasses, 'pr-8')}
        required={required}
      />
    </div>
  )
}

export function DateField({
  label,
  className,
  required = false,
  ...props
}: Omit<React.ComponentPropsWithoutRef<'input'>, 'id'> & { label?: string }) {
  const id = useId()

  return (
    <div className={className}>
      {label && (
        <Label id={id} required={required}>
          {label}
        </Label>
      )}
      <input
        id={id}
        type="date"
        {...props}
        className={formClasses}
        required={required}
      />
    </div>
  )
}

export function CheckboxField({
  label,
  className,
  labelClassName,
  required = false,
  ...props
}: Omit<React.ComponentPropsWithoutRef<'input'>, 'id' | 'type'> & {
  label?: React.ReactNode
  labelClassName?: string
}) {
  const id = useId()

  return (
    <div className={clsx('flex items-start space-x-3', className)}>
      <input
        id={id}
        type="checkbox"
        {...props}
        className="text-blueberry-600 focus:ring-blueberry-500 mt-1 h-4 w-4 rounded border-gray-300"
        required={required}
      />
      {label && (
        <label
          htmlFor={id}
          className={clsx('text-sm text-gray-600', labelClassName)}
        >
          {label}
          {required && '*'}
        </label>
      )}
    </div>
  )
}
