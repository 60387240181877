import { useId, useState, ChangeEvent, FocusEvent } from 'react'
import { formClasses, Label } from '@ui/components/inputs/Fields'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'

export function PasswordField({
  label,
  ...props
}: Omit<React.ComponentPropsWithoutRef<'input'>, 'id'> & { label?: string }) {
  const id = useId()
  const [showPassword, setShowPassword] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [isTouched, setIsTouched] = useState(false)
  const [isValid, setIsValid] = useState(true)

  // Matches Rails validation: at least 8 chars, 1 number, 1 uppercase, 1 lowercase
  const validatePassword = (password: string): boolean => {
    if (!password) return true
    const passwordRegex = /^((?=.*\d)(?=.*[a-z])(?=.*[A-Z])).{8,128}$/
    return passwordRegex.test(password)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsValid(validatePassword(e.target.value))
    props.onChange?.(e)
  }

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocused(false)
    setIsTouched(true)
    setIsValid(validatePassword(e.target.value))
  }

  const handleFocus = () => {
    setIsFocused(true)
  }

  const showError = !isFocused && !isValid && isTouched
  const helperText = showError
    ? 'Password must be at least 8 characters and contain numbers, uppercase, and lowercase letters'
    : ''

  return (
    <div>
      {label && (
        <Label id={id} required>
          {label}
        </Label>
      )}
      <div className="relative">
        <input
          id={id}
          type={showPassword ? 'text' : 'password'}
          name="password"
          autoComplete="current-password"
          required
          minLength={8}
          maxLength={128}
          className={`${formClasses} ${showError ? 'border-red-500' : ''}`}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          {...props}
        />
        <span
          className="text-blueberry-700 absolute inset-y-0 right-3 flex cursor-pointer items-center text-xs"
          onClick={() => setShowPassword(!showPassword)}
          aria-label={showPassword ? 'Hide password' : 'Show password'}
        >
          {showPassword ? (
            <EyeIcon className="size-5" />
          ) : (
            <EyeSlashIcon className="size-5" />
          )}
        </span>
      </div>
      {helperText && (
        <p
          className={`mt-1 text-sm ${showError ? 'text-red-500' : 'text-gray-500'}`}
        >
          {helperText}
        </p>
      )}
    </div>
  )
}
