import { TextField } from '@ui/components/inputs/Fields'
import { ChangeEvent, useState, FocusEvent } from 'react'

export function MobileNumberField({
  value,
  onChange,
  ...props
}: Omit<
  React.ComponentPropsWithoutRef<'input'>,
  'id' | 'value' | 'onChange'
> & {
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}) {
  const [isFocused, setIsFocused] = useState(false)
  const [isTouched, setIsTouched] = useState(false)
  const [isValid, setIsValid] = useState(true)

  const validatePhoneNumber = (phone: string): boolean => {
    return phone.length === 0 || phone.length === 10
  }

  const formatPhoneNumber = (input: string): string => {
    const cleaned = input.replace(/\D/g, '')

    if (cleaned.length >= 6) {
      return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`
    } else if (cleaned.length >= 3) {
      return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`
    } else if (cleaned.length > 0) {
      return `(${cleaned}`
    }
    return ''
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value
    const cleaned = input.replace(/\D/g, '').slice(0, 10)

    const syntheticEvent = {
      ...e,
      target: {
        ...e.target,
        value: cleaned,
      },
    }

    onChange(syntheticEvent)
    setIsValid(validatePhoneNumber(cleaned))
  }

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocused(false)
    setIsTouched(true)
    setIsValid(validatePhoneNumber(value))
  }

  const handleFocus = () => {
    setIsFocused(true)
  }

  const showError = !isFocused && !isValid && isTouched
  const helperText = showError
    ? 'Please enter a valid 10-digit phone number'
    : ''

  return (
    <TextField
      label="Phone number"
      name="phone"
      type="tel"
      autoComplete="tel-national"
      required
      placeholder="(555) 555-5555"
      value={formatPhoneNumber(value)}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      error={showError}
      helperText={helperText}
      pattern="^\(\d{3}\) \d{3}-\d{4}$"
      {...props}
    />
  )
}
