import Link from 'next/link'
import { AppleOAuthSvg, GoogleOAuthSvg } from '@ui/components/Svg'

export function OAuthLinks({
  positionOr = 'bottom',
  textOr = 'or continue with',
  textSign = 'up',
}: {
  positionOr?: 'top' | 'bottom'
  textOr?: string
  textSign?: 'up' | 'in'
}) {
  return (
    <div>
      {positionOr === 'top' && (
        <div className="relative mt-3 sm:mt-4">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-200" />
          </div>
          <div className="relative flex justify-center text-xs font-medium leading-6 sm:text-sm">
            <span className="bg-white px-6 text-gray-400">{textOr}</span>
          </div>
        </div>
      )}

      <div className="mt-3 grid grid-cols-1 gap-3 sm:mt-4 sm:grid-cols-2 sm:gap-4">
        {/* Google only className: mt-3 sm:mt-4 */}
        {/* Google and Apple className: mt-3 grid grid-cols-1 gap-3 sm:mt-4 sm:grid-cols-2 sm:gap-4 */}
        <Link
          href="/auth/google"
          className="flex w-full items-center justify-center gap-3 rounded-full bg-white p-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
        >
          <GoogleOAuthSvg />
          <span className="text-sm font-medium leading-6">
            {/* Google only className: text-sm font-medium leading-6 */}
            {/* Google and Apple className: text-sm font-medium leading-6 */}
            Sign {textSign} with Google
          </span>
        </Link>

        <Link
          href="/auth/apple"
          className="flex w-full items-center justify-center gap-3 rounded-full bg-white p-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
        >
          <AppleOAuthSvg />
          <span className="text-sm font-medium leading-6">
            Sign {textSign} with Apple
          </span>
        </Link>
      </div>

      {positionOr === 'bottom' && (
        <div className="relative my-3 sm:mt-4">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-200" />
          </div>
          <div className="relative flex justify-center text-xs font-medium leading-6 sm:text-sm">
            <span className="bg-white px-6 text-gray-400">{textOr}</span>
          </div>
        </div>
      )}
    </div>
  )
}
