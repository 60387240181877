import { TextField } from '@ui/components/inputs/Fields'
import { ChangeEvent, useState, FocusEvent } from 'react'

interface EmailFieldProps
  extends Omit<React.ComponentPropsWithoutRef<'input'>, 'id'> {
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

export function EmailField({ value, onChange, ...props }: EmailFieldProps) {
  const [isFocused, setIsFocused] = useState(false)
  const [isValid, setIsValid] = useState<boolean>(true)
  const [isTouched, setIsTouched] = useState<boolean>(false)

  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

  const validateEmail = (email: string): boolean => {
    if (!email) return true
    return emailRegex.test(email)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.trim().toLowerCase()
    const syntheticEvent = {
      ...e,
      target: { ...e.target, value: input },
    }

    onChange(syntheticEvent)
    setIsValid(validateEmail(input))
  }

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocused(false)
    setIsTouched(true)
    setIsValid(validateEmail(value))
  }

  const handleFocus = () => {
    setIsFocused(true)
  }

  const showError = !isFocused && !isValid && isTouched
  const helperText = showError ? 'Please enter a valid email address' : ''

  return (
    <TextField
      label="Email address"
      name="email"
      type="email"
      autoComplete="email"
      required
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      error={showError}
      helperText={helperText}
      inputMode="email"
      {...props}
    />
  )
}
