import Router from 'next/router'
import { toast } from 'react-hot-toast'
import { useEventTracking } from '@web-app/hooks/useEventTracking'
import { useSession } from '@web-app/hooks/useSession'
import React, { useState, useEffect, FormEvent } from 'react'
import { MobileNumberField } from '@ui/components/inputs/MobileNumberField'
import { EmailField } from '@ui/components/inputs/EmailField'
import { PasswordField } from '@ui/components/inputs/PasswordField'
import { SubmitButton } from '@ui/components/buttons/SubmitButton'
import { Spinner } from '@ui/components/Spinner'
import { AgreeToTerms } from '@ui/components/inputs/AgreeToTerms'
import { VerificationText } from '@web-app/components/auth/VerificationText'
import { OAuthLinks } from '@web-app/components/auth/OAuth'

export function RegisterForm({
  handleSubmit,
  loading,
  phone,
  setPhone,
  email,
  setEmail,
  password,
  setPassword,
}: {
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void
  loading: boolean
  phone: string
  setPhone: (value: string) => void
  email: string
  setEmail: (value: string) => void
  password: string
  setPassword: (value: string) => void
  referralCode: string
  setReferralCode: (value: string) => void
}) {
  return (
    <form onSubmit={handleSubmit}>
      <OAuthLinks />
      <div className="space-y-6">
        <MobileNumberField
          value={phone}
          onChange={(event) => setPhone(event.target.value)}
          disabled={loading}
        />
        <EmailField
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          disabled={loading}
        />
        <PasswordField
          label="Password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          disabled={loading}
        />
      </div>
      <div className="mt-4">
        <VerificationText />
      </div>
      <SubmitButton disabled={loading}>
        {loading ? (
          <>
            <Spinner className="mr-2 h-5 w-5 text-white" />
            Loading...
          </>
        ) : (
          'Sign up with email'
        )}
      </SubmitButton>
      <AgreeToTerms />
    </form>
  )
}

export function RegisterFormStandalone() {
  const { trackEvent } = useEventTracking()
  const [phone, setPhone] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [referralCode, setReferralCode] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false)
  const { setSessionData } = useSession()

  useEffect(() => {
    trackEvent('view_register', { comp: 'RegisterFormStandalone' }, true)
  }, [])

  const handleRegisterSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault()
    setIsLoading(true)

    try {
      const response = await fetch('/api/auth/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          phone,
          email,
          password,
          referral_code: referralCode,
        }),
      })

      if (response.ok) {
        const data = await response.json()
        await setSessionData(data)
        trackEvent('submit_register_success', { comp: 'RegisterPage' }, true)
        setIsSuccessful(true)
        toast.success('Successfully registered!')
        Router.push('/onboarding/verify-phone')
      } else {
        trackEvent('submit_register_error', { comp: 'RegisterPage' })
        const data = await response.json()
        throw new Error(data.message)
      }
    } catch (error: unknown) {
      console.error(`Error in handleRegisterSubmit: ${String(error)}`)
      if (error instanceof Error) {
        toast.error(error.message)
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <RegisterForm
      handleSubmit={handleRegisterSubmit}
      loading={isLoading || isSuccessful}
      phone={phone}
      setPhone={setPhone}
      email={email}
      setEmail={setEmail}
      password={password}
      setPassword={setPassword}
      referralCode={referralCode}
      setReferralCode={setReferralCode}
    />
  )
}
